import { makeStyles } from '@material-ui/core/styles';
import { pxToRem } from '../../../../styles/themes/vad/constants';

const useStyles = makeStyles(theme => {
  return {
    root: {
      display: 'flex',
      flexGrow: '1',
      '& .btnWithBorder': {
        [theme.breakpoints.down('md')]: {
          paddingTop: theme.spacing(3),
        },
      },
      '&.essential-info-card': {
        '& .discover-more-btn': {
          marginTop: pxToRem(24),
        },
        '& .MuiTypography-h2': {
          display: 'flex',
        },
        '& .AdcardContent': {
          borderRadius: 12,
          "&:hover": {
            boxShadow: "none",
            '@media (max-width: 1220px)': {
              '& .img-to-animate-wrapper': {
                transform: "none",
              },
            },
          },

          '& > .imgHeight': {
            height: '100%',
          },
          '& .imgHeight': {
            height: '100%',
            maxHeight: 'none',
            '&::before': {
              background: theme.mixins.Black(200),
            }
          },
        },
        '& .heading': {
          marginBottom: 0,
          fontSize: pxToRem(72),
          lineHeight: pxToRem(80),
          fontFamily: theme.custom.fontFamily.primaryBold,
          color: "#000",
          [theme.breakpoints.down('md')]: {
            fontSize: pxToRem(32),
            lineHeight: pxToRem(40),
            fontFamily: theme.custom.fontFamily.primaryBold,
          },
        },
        '& .card-grid': {
          paddingTop: 0,
          paddingBottom: 0,
          height: pxToRem(720),
          [theme.breakpoints.down('md')]: {
            height: pxToRem(168),
            paddingTop: pxToRem(8),
            paddingBottom: pxToRem(8),
          },
          '&:first-child': {
            paddingTop: 0,
          },
          '&:last-child': {
            paddingBottom: 0,
          }
        },
        '& .cards-wrapper': {
          marginTop: 40,
          [theme.breakpoints.down('md')]: {
            marginTop: 24,
          },
          '& .cards-wrapper-tiles-grid': {
            marginTop: 0,
            marginBottom: 0,
          },
        },
      },
    },
    heading: {
      ...theme.palette.common.ellipsis(),
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up('lg')]: {
        width: pxToRem(910),
        ...theme.palette.common.ellipsis(1),
        marginBottom: ({ isPriceCard }) =>
          isPriceCard ? theme.spacing(5) : 8,
      },
      [theme.breakpoints.up('xl')]: {
        width: '100%',
      },
    },
    titleTextLinkWrap: {
      color: `${theme.palette.secondary.main}`,
      height: props => !props.isEditor && '100%',
      display: 'block',
      '& h3': {
        ...theme.palette.common.ellipsis(2),
      }
    },
    primaryCTAWrap: {
      paddingBottom: 0,
    },
    mtop28: {
      marginTop: pxToRem(24),
      [theme.breakpoints.up('lg')]: {
        marginTop: '5.5rem'
      }
    },
    mtop25: {
      marginTop: pxToRem(25)
    }
  };
});
export default useStyles;
